#root {
    background-color: #f7f7f7;
}

main {
    background-color: white;
}

.login-button {
    background-color: #92c83e;
}

.login-button:hover {
    background-color: #7bbd3f;
}

.signup-button {
    background-color: #faa030;
}

.signup-button:hover {
    background-color: #f48a00;
}